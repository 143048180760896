<template>
  <div class="new_order_wrapper">
    <div class="header">
      <div>
        <a-button icon="arrow-left" size="small" @click="back" />
        <span class="page_type">开单</span>
      </div>
    </div>
    <div class="content">
      <component :is="OrderTemplate" :contractId="this.contractId" :id="this.id">
      </component>
    </div>
  </div>
</template>

<script>
import OrderTemplate from './components/OrderTemplate.vue';
import { mapActions } from 'vuex';

export default {
  name: 'NewSaleOrder',
  props: {
    contractId: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      OrderTemplate,
    };
  },
  methods: {
    ...mapActions([
      'getTaxRate',
      'getRate',
    ]),
    back () {
      this.$router.back();
    },
  },
  created () {
    this.getTaxRate();
    this.getRate();
  },
};
</script>

<style scoped lang="scss">
.new_order_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    height: 52px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    .page_type {
      font-size: 16px;
      font-weight: bold;
      margin-left: 1em;
    }
  }

  .content {
    flex: 1;
    padding: 16px 0 0;
    overflow: hidden;
  }
}
</style>

